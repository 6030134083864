.section {
  padding-top: 40px;
  padding-bottom: 40px;
}

.main-heading{
  color: rgb(0, 0, 0);
  font-weight: 700;
}

.sub-heading{
  font-weight: 800;
}

.underline{
  height: 04px;
  width: 8rem;
  background-color: #ffffff;
  margin-top: 10px;
  margin-bottom: 10px;
}

.bg-c-light{
  background-color: #9e8779;
}

.about-us-img{
  max-width: 100%;
  max-height: 100%;
}

.footer a{
  color: #fff;
  text-decoration: none !important;
}

.footer a:hover{
  color: gray;
}

*{
  font-family: 'Onest', sans-serif;
}

.btn-primary{
  background-color: #9e8779 !important;
  border-color: #9e8779 !important;
}

.btn-donate {
  background-color: #ffffff !important;
}

.text-white{
  color: #fff;
}

.donate-image{
  height: 100%;
  width: 100%;
}

.text-default{
  color: #9e8779 !important;
  font-weight: bold !important;
}

.form-control-height{
  height: 10rem;
}